import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { USER_BALANCE_TYPE, xTenantId } from '../../consts';
import {
  UserWalletsQuery,
  useUserWalletsLazyQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/user-walet.query.generated';
import { useUserContext } from '../../providers/UserProvider';
import { IUseUserBalanceData, IUseUserBalanceReturnType } from './types';
import { useWalletUpdatedSubscription } from '../../libs/graphql/baseAppAPI/subscriptions/__generated__/wallet-updated.generated';

const userBalanceInitState: IUseUserBalanceData = {
  cashBalance: 0,
  bonusBalance: 0,
  totalBalance: 0,
};

export const useUserBalance = (): IUseUserBalanceReturnType => {
  const auth = useAuth();
  const { userProfileId } = useUserContext();
  const [userBalance, setUserBalance] = useState<IUseUserBalanceData>(userBalanceInitState);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const onFetchBalanceCompleted = (data: UserWalletsQuery): void => {
    const balanceData = (data?.userWallets || []).reduce<IUseUserBalanceData>(
      (acc, item) => {
        if (item?.type === USER_BALANCE_TYPE.MAIN) {
          acc.cashBalance += item?.balanceAmount || 0;
        } else if (item?.type === USER_BALANCE_TYPE.BONUS) {
          acc.bonusBalance += item?.balanceAmount || 0;
        }

        acc.totalBalance += item?.balanceAmount || 0;

        return acc;
      },
      {
        cashBalance: 0,
        bonusBalance: 0,
        totalBalance: 0,
      }
    );

    setUserBalance(balanceData);
    setIsCompleted(true);
  };

  const [fetchUserBalance, { loading: isBalanceLoading }] = useUserWalletsLazyQuery({
    variables: {
      xTenantId,
      userProfileId,
    },
    onCompleted: onFetchBalanceCompleted,
  });

  const { data, error } = useWalletUpdatedSubscription({
    variables: { userProfileId },
  });

  useEffect(() => {
    const walletBalance = Number(data?.walletUpdated?.current?.balanceAmount || 0);
    const balanceType = data?.walletUpdated?.type;

    const isCash = balanceType === USER_BALANCE_TYPE.MAIN;
    const isBonus = balanceType === USER_BALANCE_TYPE.BONUS;

    const totalBalance = userBalance.totalBalance - (userBalance.totalBalance - walletBalance);
    const targetUserBalance = {
      ...userBalance,
      ...(isCash && { cashBalance: walletBalance }),
      ...(isBonus && { bonusBalance: walletBalance }),
      totalBalance,
    };

    error && console.error(error);

    walletBalance && setUserBalance(targetUserBalance);
  }, [data, error]);

  useEffect(() => {
    if (auth.isAuthenticated && !isCompleted) {
      fetchUserBalance();
    }
  }, [auth.isAuthenticated, isCompleted]);

  return { userBalance, isBalanceLoading };
};

import { addMilliseconds, format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { useTime } from '../../../../../hooks/useTime';

interface IProps {
  timeTillCoolDown: number;
}

export const TimeTillComponent: FC<IProps> = ({ timeTillCoolDown }) => {
  const { currentTime } = useTime();
  const [time, setTime] = useState(timeTillCoolDown);

  useEffect(() => {
    time && setTime((prevValue) => (prevValue ? prevValue - 1000 : 0));
  }, [currentTime]);

  return (
    <span className="time-till__snack-message">
      Mandatory break will start in {format(addMilliseconds(new Date(0), time || 0), 'm:ss')} min.
    </span>
  );
};

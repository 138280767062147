import { GameCardContainerType } from '../../components/Games/GameCard/GameCard.types';
import {
  Game,
  LobbyItem,
  GameChannel,
  LobbiesData,
  GameItemResponse,
  ILobbyBannerCollection,
  IGameCategoryResponseItem,
} from '../../components/Games/games.model';
import { LobbiesGetQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/lobbies-get.query.generated';
import { HeroBannerSlide } from '../../components/HeroBannerCarousel';
import { getImageUrl } from '../navigation';

/**
 * Extract game aspect ratio for a given GameChannel.  Defaults to 4/3
 * @param game
 * @param gameChannel
 */
export function getGameAspectRatio(game: Game, gameChannel: GameChannel): number {
  const channel = game.channels?.find((channel) => channel.channel === gameChannel);

  if (channel && typeof channel.width === 'number') {
    return channel.width / channel.height;
  }

  return 4 / 3;
}

export const transformGameData = (game: GameItemResponse | undefined | null): Game | undefined => {
  if (!game) {
    return undefined;
  }

  const { rules, short_description, long_description, display_name, slug } = game.translations?.[0] || {};

  const gameplayBackground = getImageUrl(game.gameplay_background?.filename_disk);
  const gameImage = getImageUrl(game.game_image?.filename_disk);
  const bannerImage = getImageUrl(game.banner_image?.filename_disk);

  const tags =
    game.tags?.map((tag) => {
      return { ...tag?.game_tags_id?.translations?.[0] };
    }) || null;

  const features =
    game.features?.map((feature) => {
      const { width, height, title, filename_disk } = feature?.game_features_id?.thumbnail || {};

      return {
        thumbnail: {
          width,
          height,
          title: title || '',
          imgUrl: getImageUrl(filename_disk),
        },
        description: feature?.game_features_id?.translations?.[0]?.description || '',
        displayName: feature?.game_features_id?.translations?.[0]?.display_name || '',
      };
    }) || [];

  return {
    tags,
    provider: {
      mediatorId: String(game.provider?.mediator_id),
    },
    features,
    rtp: game.rtp || null,
    gameImage: {
      ...game.game_image,
      imgUrl: gameImage,
    },
    bannerImage: {
      ...game.banner_image,
      imgUrl: bannerImage,
    },
    gameplayBackground: {
      ...game.gameplay_background,
      imgUrl: gameplayBackground,
    },
    gameId: game.game_id || '',
    maxRtp: game.max_rtp || '',
    minRtp: game.min_rtp || '',
    maxBet: game.max_bet || '',
    minBet: game.min_bet || '',
    paylines: game.paylines || '',
    channels: [
      {
        name: GameChannel.Desktop,
        channel: GameChannel.Desktop,
        width: 16,
        height: 9,
        isAvailableForDemoPlay: true,
      },
      {
        name: GameChannel.Mobile,
        channel: GameChannel.Mobile,
        width: 4,
        height: 3,
        isAvailableForDemoPlay: true,
      },
    ],
    rules: rules || '',
    friendlyUrlName: slug || '',
    displayName: display_name || '',
    longDescription: long_description || '',
    shortDescription: short_description || '',
    categoryName: game.category?.internal_name || '',
  };
};

const flattenBannerCollection = (data: LobbiesGetQuery): ILobbyBannerCollection | null => {
  const lobby = data.lobbies?.[0];

  if (!lobby.banner_collection) {
    return null;
  }

  const banners = lobby.banner_collection?.banners;
  const staticBanner = lobby.banner_collection?.static_banner;
  const staticBannerImgSrc = getImageUrl(staticBanner?.thumbnail?.filename_disk);

  const dynamicBanners: HeroBannerSlide[] =
    banners?.map((bannerItem): HeroBannerSlide => {
      const banner = bannerItem?.banners_id;
      const bannerTranslations = banner?.translations?.[0];
      const bannerMobileImgSrc = getImageUrl(banner?.mobile_image?.filename_disk);
      const bannerDesktopImgSrc = getImageUrl(banner?.desktop_image?.filename_disk);

      return {
        mobileImgSrc: bannerMobileImgSrc,
        imgSrc: bannerDesktopImgSrc,
        heading: bannerTranslations?.title || '',
        imgAlt: banner?.desktop_image?.title || '',
        footer: bannerTranslations?.sub_content || '',
        subHeading: bannerTranslations?.subtitle || '',
        buttonLink: bannerTranslations?.link_URL || '',
        buttonLabel: bannerTranslations?.link_text || '',
        openInNewTab: bannerTranslations?.open_in_new_tab,
      };
    }) || [];

  return {
    staticBanner: {
      imgSrc: staticBannerImgSrc,
      imgAlt: staticBanner?.thumbnail?.title || '',
      buttonLink: staticBanner?.translations?.[0]?.link_URL || '',
      openInNewTab: staticBanner?.translations?.[0]?.open_in_new_tab,
      // TODO: replace static colors with dynamic data when it appears in the response
      tag: staticBanner?.translations?.[0]?.tag
        ? { text: staticBanner?.translations?.[0]?.tag, backgroundColor: '#FF5733', textColor: '#FFFFFF' }
        : undefined,
    },
    banners: dynamicBanners || [],
  };
};

export const transformLobbyData = (data: LobbiesGetQuery): LobbiesData | undefined => {
  const lobby = data.lobbies?.[0];

  if (!lobby) {
    return undefined;
  }

  const bannerCollection = flattenBannerCollection(data);

  const lobbyItems: LobbyItem[] =
    lobby.categories?.map(
      (cat) =>
        ({
          icon: cat?.categories_id?.icon || '',
          internalName: cat?.categories_id?.internal_name || '',
          slug: cat?.categories_id?.translations?.[0]?.slug || '',
          title: cat?.categories_id?.translations?.[0]?.title || '',
          description: cat?.categories_id?.translations?.[0]?.description || '',
          carouselType: (cat?.categories_id?.carousel_type as GameCardContainerType) || GameCardContainerType.Wide,
          games: cat?.categories_id?.games?.map((game) => transformGameData(game?.game_id) as Game).filter(Boolean),
        }) as LobbyItem
    ) || [];

  return {
    lobbyItems,
    bannerCollection,
    showCategories: Boolean(lobby.show_categories),
    generalDescription: lobby.translations?.[0]?.description || '',
  };
};

export const transformArrayGameData = (data: Array<GameItemResponse>): Game[] => {
  return data.map((item: GameItemResponse) => transformGameData(item) as Game).filter(Boolean);
};

export const filterLobbyData = (data: LobbiesData | null, filterKey: string): LobbiesData | null => {
  if (!data) return null;

  return {
    ...data,
    lobbyItems: data.lobbyItems.filter((item) => item.slug === filterKey),
  };
};

export const transformCategoriesGameData = (data: IGameCategoryResponseItem[]): Game[] => {
  const games = data?.[0].games;

  if (!games?.length) {
    return [];
  }

  return games.map((item) => transformGameData(item?.game_id) as Game).filter(Boolean);
};

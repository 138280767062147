import { FC, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MediaBreakpoint, useBreakpointInfo } from '../../hooks/useDeviceInfo';
import { setIsNavigationExpanded } from '../../state/app/reducers';
import { useSnackbar } from '../../hooks/snackbar';
import { selectIsErrorDuringGeoCheck, selectIsGeoLocationAllowed } from '../../state/app';
import { useGlobalGameSession } from '../../hooks/games/useGlobalGameSession';

/**
 * Sets navigation expansion based on screen width on application load
 */
export const LayoutInit: FC = () => {
  const { addSnack } = useSnackbar();
  const dispatch = useDispatch();
  const aboveDesktop = useBreakpointInfo([MediaBreakpoint.BIG_DESKTOP]);
  const geoLocationAllowed = useSelector(selectIsGeoLocationAllowed);
  const errorDuringGeoCheck = useSelector(selectIsErrorDuringGeoCheck);

  useGlobalGameSession();

  useLayoutEffect(() => {
    // Desktops have nav expanded by default
    dispatch(setIsNavigationExpanded(aboveDesktop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !geoLocationAllowed &&
      addSnack({
        type: 'error',
        message:
          'Sorry, you are not able to register from your current location. Please see our help pages for more info',
      });
  }, [geoLocationAllowed]);

  useEffect(() => {
    if (errorDuringGeoCheck) {
      addSnack({
        type: 'error',
        message: 'Something went wrong during ip check',
      });
    }
  }, [errorDuringGeoCheck]);

  return null;
};

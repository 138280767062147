import { FC, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from './components/Authentification/AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const KeycloakMain = lazy(() =>
  // eslint-disable-next-line prettier/prettier
  import('./keycloak-theme/main').then(({ KeycloakMain }) => ({ "default": KeycloakMain }))
);

// Set this to true to enable debug mode when developing keycloak themes.
// We do this to avoid including the entire keycloak build in the initial bundle.
const keycloakDebugMode = false;

const Application: FC = () => {
  const isKeycloak = (window as unknown as { kcContext: unknown }).kcContext !== undefined || keycloakDebugMode;

  if (isKeycloak) {
    return <KeycloakMain />;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  );
};

root.render(<Application />);

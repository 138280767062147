import React, { FC } from 'react';
import { AccountTile } from '../../../Account/MyAccount/AccountTile';
import { AccountTileListItem } from '../../../Account/MyAccount/AccountTileListItem';
import { Button } from '../../../shared/Button';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { AccountTileRealityCheckItem } from './AccountTileRealityCheckItem';
import './RealityChecksSection.scss';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { Trans } from '../../../shared/Trans';

interface RealityChecksSectionProps {
  interval: number;
  /**
   * Optional to add a spinner before loading a component.
   */
  loading: boolean;
  /**
   * Value of the Select element in the format 'time text' (ex.: 15 minutes, 1 hours)
   */
  intervalWithLabel?: string;
  /**
   * Callback that's executed when the user opens the HowItWorks modal
   */
  openHowItWorksModal: () => void;
  /**
   * Callback that's executed when the user opens the EditModal
   */
  openEditModal: () => void;
}

export const RealityChecksSection: FC<RealityChecksSectionProps> = ({
  interval,
  loading,
  intervalWithLabel,
  openHowItWorksModal,
  openEditModal,
}) => {
  const { t } = useTranslations();
  const isIntervalSet = interval > 0;
  const text = intervalWithLabel || '';

  return (
    <div className="reality-checks-section">
      <Heading level={6}>{t('reality-check.title')}</Heading>

      <Paragraph className="reality-checks-section__subtext" size="xs" noMargin>
        <Trans keyProp="reality-check.limit.info">
          <strong>Info on time limit</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim, et et,
          habitant fringilla
          <Button type="button" variant="text" className="reality-checks-section__link" onClick={openHowItWorksModal}>
            how it works
          </Button>
        </Trans>
      </Paragraph>

      <AccountTile noPadding>
        {isIntervalSet ? (
          <AccountTileRealityCheckItem text={text} onOpenEditModal={openEditModal} />
        ) : (
          <AccountTileListItem loading={loading} text={text} icon="Plus" iconAlign="right" onClick={openEditModal} />
        )}
      </AccountTile>
    </div>
  );
};

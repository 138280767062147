import cookie from 'js-cookie';
import React, { Children, FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Orientation, useDeviceInfo, useOrientation } from '../../../../hooks/useDeviceInfo';
import { setGameLaunchTime } from '../../../../state/game/reducers';
import { joinStrings } from '../../../../utils/string';
import { Button } from '../../../shared/Button';
import { MenuBtn, MenuReturnArrow } from '../../../shared/SVG/Icons';
import { TouchDrawer } from '../../../shared/TouchDrawer';
import { RecommendedGameCarousel } from '../../RecommendedGames';
import { GameSessionNetPosition } from './../../GameSession/';
import GameMenuBalance from './GameMenuBalance';
import { GameMenuButtons } from './GameMenuButtons';
import GameMenuTile from './GameMenuTile';
import { GameSessionDuration } from './GameSessionDuration';
import './styles/index';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

// TODO: add the correct logic
const { positionResumeGame = '1', positionBalance = '2', positionRecentlyPlayed = '3', positionNavigation = '4' } = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentsPositions: any[] = [positionResumeGame, positionBalance, positionRecentlyPlayed, positionNavigation];

const sortMenu = (componentMenu: Array<React.ReactNode>): React.ReactNode[] => {
  const sortedItems: React.ReactNode[] = [];

  componentsPositions.forEach((componentPosition, index) => {
    sortedItems[componentPosition] = componentMenu[index];
  });

  return sortedItems;
};

const DesktopGameMenu: FunctionComponent<
  Pick<DeviceGameMenuProps, 'onOpenGameSearch' | 'setShowMenu' | 'showMenu'>
> = ({ showMenu, setShowMenu, onOpenGameSearch }) => {
  const { t } = useTranslations();
  const isMobile = useDeviceInfo().isMobileDevice;

  const [showDeposit, setShowDeposit] = useState<boolean>(false);

  const toggleDeposit = (): void => setShowDeposit(!showDeposit);

  const onClickMenuShow = (): void => {
    setShowMenu(!showMenu);
  };

  const onClickMenuResume = (): void => {
    setShowMenu(!showMenu);
  };

  const onMenuClose = (): void => {
    setShowMenu(!showMenu);
  };

  const componentsMenu: JSX.Element[] = [
    <Button
      type="button"
      variant="text"
      icon={<MenuReturnArrow />}
      iconPosition="left"
      onClick={onClickMenuResume}
      className="desktop-game-menu__menu-button"
    >
      {t('game.resume')}
    </Button>,
    <GameMenuBalance openDeposit={toggleDeposit} />,
    <GameMenuTile>
      <RecommendedGameCarousel onClose={onMenuClose} isCarousel={false} />
    </GameMenuTile>,
    <GameMenuTile className="desktop-game-menu__links">
      <GameMenuButtons onClose={onMenuClose} onOpenGameSearch={onOpenGameSearch} />
    </GameMenuTile>,
  ];

  return (
    <div className="desktop-game-menu">
      <Button
        type="button"
        variant="text"
        icon={<MenuBtn />}
        iconPosition="left"
        onClick={onClickMenuShow}
        className="desktop-game-menu__menu-button"
      >
        {t('game.menu')}
      </Button>
      {showMenu && (
        <TouchDrawer
          location="left"
          show
          onClose={onMenuClose}
          mode="dark"
          backdropCanClose
          backdropClassName={joinStrings([
            'desktop-game-menu__drawer',
            !isMobile && 'desktop-game-menu__drawer--desktop',
          ])}
          style={{ overlay: { zIndex: 5 } }}
        >
          {Children.toArray(sortMenu(componentsMenu)?.map((component) => component))}
        </TouchDrawer>
      )}
      <div className="game-launcher-menu__right">
        {/* TODO: implement quick deposit */}
        <GameSessionNetPosition />
        <GameSessionDuration />
      </div>
    </div>
  );
};

type DeviceGameMenuProps = {
  orientation: 'landscape' | 'portrait';
  haveLaunched: boolean;
  iframeId: string;
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
  onOpenGameSearch: () => void;
};
const DeviceGameMenu: FunctionComponent<DeviceGameMenuProps> = ({
  orientation,
  iframeId,
  showMenu,
  haveLaunched,
  setShowMenu,
  onOpenGameSearch,
}) => {
  const { t } = useTranslations();
  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  const isMobile = useDeviceInfo().isMobileDevice;

  const [showDeposit, setShowDeposit] = useState(false);
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;

  const toggleDeposit = (): void => setShowDeposit(!showDeposit);

  useLayoutEffect(() => {
    if (!ref.current || !haveLaunched) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, haveLaunched]);

  useEffect(() => {
    const className = `page--game-launcher-${orientation}`;

    document.body.classList.add(className);

    return () => document.body.classList.remove(className);
  }, [orientation]);

  const onClickMenuShow = (): void => {
    setShowMenu(!showMenu);
  };

  const onClickMenuResume = (): void => {
    setShowMenu(!showMenu);
  };

  const onMenuClose = (): void => {
    setShowMenu(!showMenu);
  };

  const componentMenuDevice = [
    <Button
      type="button"
      variant="text"
      icon={<MenuReturnArrow />}
      iconPosition="left"
      onClick={onClickMenuResume}
      className="device-game-menu__menu-button"
    >
      {t('game.resume')}
    </Button>,
    <GameMenuBalance openDeposit={toggleDeposit} />,
    <GameMenuTile>
      <RecommendedGameCarousel onClose={onMenuClose} isCarousel={false} />
    </GameMenuTile>,
    <GameMenuTile className="device-game-menu__links">
      <GameMenuButtons onClose={onMenuClose} onOpenGameSearch={onOpenGameSearch} />
    </GameMenuTile>,
  ];

  return (
    <div ref={ref} className={`device-game-menu device-game-menu--${orientation}`}>
      <Button
        type="button"
        variant="text"
        icon={<MenuBtn />}
        iconPosition="left"
        onClick={onClickMenuShow}
        className="device-game-menu__menu-button"
      >
        {t('game.menu')}
      </Button>
      {showMenu && (
        <TouchDrawer
          location={!isLandscape ? 'top' : 'left'}
          show
          onClose={onMenuClose}
          mode="dark"
          backdropCanClose
          backdropClassName={joinStrings([
            'device-game-menu__drawer',
            isMobile && 'device-game-menu__drawer--mobile',
            isLandscape && 'device-game-menu__drawer--landscape',
          ])}
          style={{ overlay: { zIndex: 5 } }}
        >
          {Children.toArray(sortMenu(componentMenuDevice).map((component) => component))}
        </TouchDrawer>
      )}
      {/* TODO: implement quick deposit */}
      <div className="game-launcher-menu__right">
        <GameSessionNetPosition />
        <GameSessionDuration />
      </div>
    </div>
  );
};

const GameSessionLauncher: FunctionComponent = () => {
  // TODO: implement useGameSession
  const isSessionReady = true;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSessionReady) {
      dispatch(setGameLaunchTime());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionReady]);

  return null;
};

export type GameLauncherMenuProps = {
  showMenu: boolean;
  iframeId: string;
  haveLaunched: boolean;
  onOpenGameSearch: () => void;
  setShowMenu: (value: boolean) => void;
  children?: React.ReactNode;
};

export const GameLauncherMenu: FunctionComponent<GameLauncherMenuProps> = ({
  showMenu,
  children,
  iframeId,
  haveLaunched,
  setShowMenu,
  onOpenGameSearch,
}) => {
  const isMobile = useDeviceInfo().isMobileDevice;
  const [isLandscape, setIsLandscape] = useState(useOrientation() === Orientation.LANDSCAPE);

  useEffect(() => {
    const gameLaunchCount = +(cookie.get('gameLaunchCount') || 0) + 1;

    cookie.set('gameLaunchCount', gameLaunchCount.toString());

    window.matchMedia('(orientation:landscape)').addEventListener('change', (event) => setIsLandscape(event.matches));
  }, []);

  // TODO: add the correct logic
  useEffect(() => {
    console.log('setSpinzoneMenuActive', true);

    return () => {
      console.log('setSpinzoneMenuActive', false);
    };
  }, []);

  return (
    <div
      className={joinStrings([
        'game-launcher-menu',
        !isMobile && 'game-launcher-menu--desktop',
        isMobile && `game-launcher-menu--device-${isLandscape ? 'landscape' : 'portrait'}`,
      ])}
    >
      <GameSessionLauncher />
      {!isMobile && (
        <DesktopGameMenu showMenu={showMenu} setShowMenu={setShowMenu} onOpenGameSearch={onOpenGameSearch} />
      )}
      {isMobile && isLandscape && (
        <DeviceGameMenu
          orientation="landscape"
          iframeId={iframeId}
          haveLaunched={haveLaunched}
          onOpenGameSearch={onOpenGameSearch}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}

      {isMobile && !isLandscape && (
        <DeviceGameMenu
          orientation="portrait"
          iframeId={iframeId}
          haveLaunched={haveLaunched}
          onOpenGameSearch={onOpenGameSearch}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}
      {children}
    </div>
  );
};

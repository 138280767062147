import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_URLS } from '../../../consts';
import { useSnackbar } from '../../../hooks/snackbar';
import { useUserContext } from '../../../providers/UserProvider';
import { storageRealityCheckTime } from '../../../utils/storage';
import { Button } from '../../shared/Button';
import { HowItWorksModal } from './HowItWorksModal';
import { getRealityCheckOptions } from './mocks';
import { RealityChecksEditModal, RealityChecksFormData } from './RealityChecksEditModal';
import { RealityChecksSection } from './RealityChecksSection';
import './RealityChecks.scss';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

const DIGIT_REGEX = /\d+/g;

type RealityChecksProps = {
  isModal: boolean;
  loading?: boolean;
  onClose?: () => void;
  finallyReturnToGamblingControl?: boolean;
};
export const RealityChecks: FC<RealityChecksProps> = ({
  isModal,
  loading = false,
  onClose = noop,
  finallyReturnToGamblingControl = true,
}) => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();
  const { userProfileId } = useUserContext();

  // TODO: replace local storage value with real data
  const [sessionReminderInterval, setSessionReminderInterval] = useState(
    (storageRealityCheckTime.get(userProfileId) as number) || 0
  );
  const [displayEditModal, setDisplayEditModal] = useState<boolean>(false);
  const [displayHowItWorks, setHowItWorks] = useState<boolean>(false);
  const [intervalWithLabel, setIntervalWithLabel] = useState<string>('');

  const onEditModalClose = (): void => setDisplayEditModal(false);

  const convertData = (interval: string): number => {
    const parsedInterval = interval.match(DIGIT_REGEX);
    const resultInterval = parsedInterval ? parseInt(parsedInterval[0]) : 0;

    if (resultInterval >= 1 && resultInterval <= 9) {
      return resultInterval * 60 + (parsedInterval && parsedInterval[1] ? parseInt(parsedInterval[1]) : 0);
    }

    return resultInterval;
  };

  useEffect(() => {
    isModal && setDisplayEditModal(true);
  }, []);

  const makeLabelFromInterval = (interval: number): void => {
    switch (interval) {
      case 60:
        setIntervalWithLabel(t('reality-check.limit.first.text'));
        break;
      case 120:
        setIntervalWithLabel(t('reality-check.limit.second.text'));
        break;
      case 0:
        setIntervalWithLabel(t('reality-check.limit.add.text'));
        break;
      default:
        setIntervalWithLabel(t('reality-check.limit.add.interval.text', { interval }));
        break;
    }
  };

  const getLabelFromOptions = (interval: number): void => {
    const MINUTES_IN_HOUR = 60;
    const hours = Math.floor(interval / MINUTES_IN_HOUR);
    const minutes = interval % MINUTES_IN_HOUR;

    const isTimePositive = (time: string): boolean => !!parseInt(time);
    const filteredInterval = [`${hours}`, `${minutes}`].filter(isTimePositive) || [];
    const isOptionEqualToInterval = ({ label }: { label: string }): boolean =>
      isEqual(label.match(DIGIT_REGEX) || [], filteredInterval);

    setIntervalWithLabel(getRealityCheckOptions(t).find(isOptionEqualToInterval)?.label || '');
  };
  const onUndoReminderInterval = async (interval: number): Promise<void> => {
    try {
      // TODO: setInterval + refetch
      console.log('sessionReminderInterval is ', interval);
      storageRealityCheckTime.set({ value: interval, playerId: userProfileId });
      setSessionReminderInterval(interval);
    } catch (err) {
      addSnack({
        type: 'error',
        theme: 'dark',
        message: t('errors.try-later'),
      });
    }
  };

  const onFormSubmit = async (formData: RealityChecksFormData): Promise<void> => {
    const { interval } = formData;

    try {
      const sessionInterval = convertData(interval);

      // TODO: setInterval + refetch
      console.log('sessionReminderInterval is ', sessionInterval);
      storageRealityCheckTime.set({ value: sessionInterval, playerId: userProfileId });
      setSessionReminderInterval(sessionInterval);
      addSnack({
        type: 'success',
        theme: 'dark',
        message: t('reality-check.updated.text'),
        // eslint-disable-next-line react/display-name
        action: () => (
          <Button
            type="button"
            variant="text"
            size="medium"
            className="reality-checks__undo-btn"
            onClick={(): Promise<void> => onUndoReminderInterval(sessionReminderInterval)}
          >
            {t('buttons.undo')}
          </Button>
        ),
      });
    } catch (err) {
      addSnack({
        type: 'error',
        theme: 'dark',
        message: t('errors.try-later'),
      });
    }

    onEditModalClose();
  };

  useEffect(() => {
    // In case there are no options for Select filled in AEM, we make label,
    // otherwise, get appropriate label from options
    if (getRealityCheckOptions(t)) {
      getLabelFromOptions(sessionReminderInterval);
    } else {
      makeLabelFromInterval(sessionReminderInterval);
    }
  }, [sessionReminderInterval]);

  const onCloseHowItWorks = (): void => {
    setHowItWorks(false);
  };

  const onCloseEdit = (): void => {
    onClose();
    onEditModalClose();
    finallyReturnToGamblingControl && navigate(APP_URLS.myAccount.gamblingControls);
  };

  const onCancelEdit = (): void => {
    onEditModalClose();
    onClose();
    finallyReturnToGamblingControl && navigate(APP_URLS.myAccount.gamblingControls);
  };

  const onSubmitEdit = (formData: RealityChecksFormData): void => {
    onFormSubmit(formData);
    onClose();
    finallyReturnToGamblingControl && navigate(APP_URLS.myAccount.gamblingControls);
  };

  const label = sessionReminderInterval ? intervalWithLabel : t('reality-check.limit.add.text');

  return (
    <div className="reality-checks">
      {!isModal && (
        <div>
          <RealityChecksSection
            interval={sessionReminderInterval}
            intervalWithLabel={label}
            openHowItWorksModal={(): void => setHowItWorks(true)}
            openEditModal={(): void => {
              setDisplayEditModal(true);
            }}
            loading={loading}
          />
          <HowItWorksModal isOpen={displayHowItWorks} onClose={onCloseHowItWorks} />
        </div>
      )}
      <RealityChecksEditModal
        isOpen={displayEditModal}
        intervalWithLabel={intervalWithLabel}
        onClose={onCloseEdit}
        onFormSubmit={onSubmitEdit}
        onCancel={onCancelEdit}
        loading={loading}
      />
    </div>
  );
};

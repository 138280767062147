export const getRealityCheckOptions = (
  t: (arg: string, arg2: { time: number }) => string
): { value: string; label: string }[] => {
  const option1 = t('reality-check.option.minutes', { time: 15 });
  const option2 = t('reality-check.option.minutes', { time: 30 });
  const option3 = t('reality-check.option.minutes', { time: 45 });
  const option4 = t('reality-check.option.hour', { time: 1 });

  return [
    {
      value: option1,
      label: option1,
    },
    {
      value: option2,
      label: option2,
    },
    {
      value: option3,
      label: option3,
    },
    {
      value: option4,
      label: option4,
    },
  ];
};

import uniqueId from 'lodash/uniqueId';
import React, { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import noop from 'lodash/fp/noop';
import { GameItem as DefaultGameItem } from './GameItem';
import { GameFilterCarousel, GameItemUIComponent, GamesListUIComponent } from './GamesList.types';
import { Notification } from '../../shared/Notification';
import { GridColumnAmount } from '../../shared/Grid';
import { Heading, HeadingLevel } from '../../shared/Heading';
import { Game } from '../games.model';
import { ProductCategoryLink } from '../../Navigation/types';
import { useGameSlidesCount } from '../../../hooks/games';
import { Button } from '../../shared/Button';
import { getGameUrl } from '../helper';
import { DefaultCarousel } from './DefaultCarousel';
import './GamesList.scss';

export type GamesListErrorProps = {
  show?: boolean;
};
export type GamesListErrorComponent = React.ComponentType<GamesListErrorProps>;

const GamesListErrorNotification: GamesListErrorComponent = ({ show }) => {
  return (
    <Fragment>
      {show && (
        <Notification type="error" hasBoxShadow>
          Error
        </Notification>
      )}
    </Fragment>
  );
};

export type GameFilters = {
  gameOptionValue?: string;
  gameOption?: GameFilterCarousel;
  provider?: string | null;
  tags?: string[];
};

export interface GamesListProps {
  /**
   * Optional number of columns. Defaults to 4
   */
  columns?: GridColumnAmount;
  /**
   * Optional. Defaults to false.  Will show a button which allows the user to load more games.
   */
  allowLoadMore?: boolean;
  /**
   * Optionally override the default list renderer (which is GamesCarousel).
   */
  ListComponent?: GamesListUIComponent;
  /**
   * Optionally override the default game item component which the list renderer should use.
   */
  GameComponent?: GameItemUIComponent;
  /**
   * Text for load more games button. Optional, default translation is fallback.
   */
  loadMoreButtonTitle: string;
  /**
   * The initial number of games to request and display.
   */
  defaultNumberOfGames?: number;
  /**
   * The category of games to display in the grid.
   */
  category?: string;
  /**
   * Switch sorting games by category or tag.
   */
  gameOption?: GameFilterCarousel;
  /**
   * Value by which games are requested for the carousel.
   */
  gameOptionValue?: string;
  /**
   * Optional.  Whether to display the name of the individual games.  Defaults to true.
   */
  showGameName?: boolean;
  /**
   * Optional. Whether to show a title in the GamesGrid header. Defaults to true.
   */
  showTitle?: boolean;
  /**
   * Optional Heading level. Defaults to 4.
   */
  headingLevel?: HeadingLevel;
  /**
   * Title for the component heading.
   */
  title: string;
  /**
   * Used as a fallback if a suitable image for a game can not be found.
   */
  fallbackImageUrl: string;
  /**
   *  Used as a fallback if a suitable image for a game can not be found.
   */
  fallbackImageAltText: string;
  /**
   * Optional, defaults to false. Used to select different translation text.
   */
  recent?: boolean;
  /**
   * Optional, defaults to false. Renders nothing when there are no games.
   */
  hideWhenEmpty?: boolean;
  /**
   * gameFilters store namespace
   */
  gameFiltersNamespace?: string;
  /**
   * Games carousel rows quantity
   */
  rowsNumber?: number;
  /**
   * Desktop slides count
   */
  desktopSlideCount: number;
  /**
   * Big desktop slides count
   */
  bigDesktopSlideCount: number;
  /**
   * Mobile slides count
   */
  mobileSlideCount: number;
  /**
   * Tablet slides count
   */
  tabletSlideCount: number;

  catalogue?: Array<Game>;
  /**
   * Name of active sub category for analytics purpose
   */
  activeSubNavCategory?: ProductCategoryLink | undefined;
  /**
   * Check wether carousel has multiple rows
   */
  isMultiple?: boolean;
}

const skeletonTile = {
  gameId: '',
  game: {
    provider: '',
  },
  categoryName: '',
  features: [],
  bannerImage: {
    imgUrl: '',
  },
  displayName: 'skeleton-tile',
  friendlyUrlName: 'skeleton',
  gameplayBackground: {
    imgUrl: '',
  },
  gameImage: {
    imgUrl: '',
  },
  tags: [],
  channels: [],
};

// 16 is the current maximum visible games number
const skeletonArray = new Array(16).fill(null).map(() => ({
  ...skeletonTile,
  game_id: uniqueId(),
}));

/**
 * Fetch and display games from the Games API endpoint.
 */
export const GamesList: FunctionComponent<GamesListProps> = ({
  columns = 4,
  gameOptionValue,
  defaultNumberOfGames = 8,
  fallbackImageAltText,
  fallbackImageUrl,
  showGameName = false,
  showTitle = false,
  allowLoadMore = false,
  loadMoreButtonTitle,
  gameFiltersNamespace,
  recent = false,
  hideWhenEmpty = false,
  GameComponent: GameItem = DefaultGameItem,
  ListComponent = DefaultCarousel,
  headingLevel = 4,
  title,
  gameOption,
  bigDesktopSlideCount,
  mobileSlideCount,
  tabletSlideCount,
  desktopSlideCount,
  rowsNumber = 1,
  catalogue,
  activeSubNavCategory,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const skeletonSlidesCount =
    useGameSlidesCount({
      wide: bigDesktopSlideCount,
      desktop: desktopSlideCount,
      tablet: tabletSlideCount,
      mobile: mobileSlideCount,
    }) * rowsNumber;

  const resultSkeletonArray = skeletonArray.slice(0, skeletonSlidesCount);

  const filterCategory = undefined;
  const carouselCategory = undefined;
  const isUserAuthenticated = false;
  const category = filterCategory || carouselCategory;

  const {
    games,
    fetchGames,
    fetchError = false,
    isFetching,
    resetPagination,
    totalNumberOfGames = 4,
  } = catalogue && catalogue.length
    ? {
        games: catalogue,
        fetchGames: noop,
        fetchError: false,
        isFetching: false,
        resetPagination: noop,
        totalNumberOfGames: 4,
      }
    : {
        games: [],
        fetchGames: noop,
        fetchError: false,
        isFetching: false,
        resetPagination: noop,
        totalNumberOfGames: 4,
      };

  useEffect(() => {
    // This will cause an initial data fetch to be done on first load or if query params change
    resetPagination && resetPagination();
  }, [category, resetPagination]);

  const onPlayDemo = (game: Game): void => {
    console.log('onPlayDemo: ', game);
  };

  const onPlayGame = (game: Game): void => {
    console.log('onPlayGame: ', game);
  };

  const onViewGameInfo = (game: Game): void => {
    console.log('onViewGameInfo: ', game);
  };

  const canLoadMore = allowLoadMore && totalNumberOfGames > 0 && games.length !== totalNumberOfGames;

  const renderComponent = recent ? !hideWhenEmpty : !hideWhenEmpty;

  const showNotification = !fetchError && !isFetching && (!recent || isUserAuthenticated);

  const showFooter = canLoadMore || showNotification;

  return renderComponent ? (
    <div className="games-list" ref={gridRef}>
      {showTitle && (
        <Heading level={headingLevel} className="games-list__header">
          {title}
        </Heading>
      )}
      <GamesListErrorNotification show={fetchError} />
      <ListComponent
        columns={columns}
        games={isFetching && title !== 'Recent Activity' ? resultSkeletonArray : games}
        loading={isFetching}
        fallbackImageAltText={fallbackImageAltText}
        fallbackImageUrl={fallbackImageUrl}
        showGameName={showGameName}
        GameItem={GameItem}
        getGameUrl={getGameUrl}
        onViewGameInfo={onViewGameInfo}
        onPlayDemo={onPlayDemo}
        onPlayGame={onPlayGame}
        pagination="normal"
      />
      {showFooter && (
        <div className="games-list__footer">
          {canLoadMore && (
            <div className="games-list__actions">
              <Button
                data-testid="load-more-games-btn"
                disabled={isFetching}
                variant="secondary"
                type="button"
                title={loadMoreButtonTitle || 'Load More'}
                onClick={fetchGames}
              >
                {loadMoreButtonTitle || 'View More'}
              </Button>
            </div>
          )}

          {/* {showNotification && (
            <Notification type='info' hasBoxShadow>
              {t(recent ? 'games-list-no-recent-games' : 'games-list-no-games')}
            </Notification>
          )} */}
        </div>
      )}
    </div>
  ) : null;
};

import React, { Children, FunctionComponent, useEffect, useState } from 'react';
import { usePasswordReset } from '../../../../hooks/auth/useResetPassword';
import { useSnackbar } from '../../../../hooks/snackbar';
import { createTimeZoneInfo } from '../../../../utils/time-zone';
import { CookiesPreferences } from '../../../Compliance/CookiesPreferences';
import { openNewWindow } from '../../../Navigation/NavigationLink/helper';
import { Heading } from '../../../shared/Heading';
import { AccountDetails } from '../AccountDetails';
import { AccountPageWrapper, AccountPageWrapperProps } from '../AccountPageWrapper';
import { AccountTile } from '../AccountTile';
import { AccountTileListItem } from '../AccountTileListItem';
import { AccountDetailsEditModal } from '../MyProfile/AccountDetailsEditModal';
import { AccountDetailsSummary } from '../MyProfile/AccountDetailsSummary';
import { AutoWithdrawal } from './AutoWithdrawal';
import { KambiOddsFormat } from './KambiOddsFormat';
import './MyAccountSettings.scss';
import { useNavigate } from 'react-router-dom';
import { redirectHelper } from './helper';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type MyAccountSettingsProps = {
  /**
   * Function to handle false animation caused by deep-linking
   */
  handleAnimation?: (arg: boolean) => void;
  /**
   * Function to navigate to homepage
   */
  onGoToHome: (withRefresh: boolean) => void;
} & Pick<AccountPageWrapperProps, 'onClose' | 'elementToFocusOnOpen' | 'hasBackButton' | 'hasCloseButton'>;

export type SettingsBlock = {
  myAccountSettingsOption?: string | null;
  myAccountSettingsSubTitle?: string | null;
};

export enum MyAccountSettingsOption {
  securitySettings = 'securitySettings',
  communicationsSettings = 'communicationsSettings',
  contactSettings = 'contactSettings',
  kambiOddsFormatSettings = 'kambiOddsFormatSettings',
  timeZoneSettings = 'timeZoneSettings',
  closeAccountSetting = 'closeAccountSetting',
  otherSettings = 'otherSettings',
}

export enum MyAccountSettingsSubTitle {
  security = 'Security',
  communication = 'Communication',
  timeZone = 'Time zone',
  oddsFormat = 'Odds format',
}

export const MyAccountSettings: FunctionComponent<MyAccountSettingsProps> = ({
  onClose,
  onGoToHome,
  hasCloseButton = true,
  hasBackButton = true,
  elementToFocusOnOpen,
}) => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const updatePasswordUrl = usePasswordReset();

  const myAccountSettingsBlock = [
    { myAccountSettingsOption: MyAccountSettingsOption.securitySettings },
    { myAccountSettingsOption: MyAccountSettingsOption.timeZoneSettings },
    { myAccountSettingsOption: MyAccountSettingsOption.kambiOddsFormatSettings },
    { myAccountSettingsOption: MyAccountSettingsOption.communicationsSettings },
  ];

  const [cookiePreferencesOpen, setCookiePreferencesOpen] = useState(false);
  const timeZone = Intl.DateTimeFormat('ar-EG').resolvedOptions().timeZone;

  const { gmt, town } = createTimeZoneInfo(timeZone);
  const navigate = useNavigate();

  const closeCookiePreferences = (): void => {
    setCookiePreferencesOpen(false);
  };

  const openCookiePreferences = (): void => {
    setCookiePreferencesOpen(true);
  };

  function handleChangePassword(): void {
    openNewWindow(updatePasswordUrl, '_self');
  }

  const handleUpdateSuccess = (updateType: string): void => {
    const isContactDetails = updateType === 'contact-details';

    addSnack({
      theme: 'dark',
      type: 'success',
      message: isContactDetails ? 'Contact details updated' : 'Marketing details updated',
    });
  };

  const handleUpdateFailure = (updateType: string): void => {
    const isContactDetails = updateType === 'contact-details';

    addSnack({
      type: 'error',
      theme: 'dark',
      message: isContactDetails ? 'Contact details error' : 'Marketing details error',
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('kc_action_status') && urlParams.get('kc_action_status') === 'success') {
      redirectHelper(navigate);
    }
  }, [window.location.search]);

  return (
    <AccountPageWrapper
      hasBackButton={hasBackButton}
      hasCloseButton={hasCloseButton}
      onClose={onClose}
      elementToFocusOnOpen={elementToFocusOnOpen}
      className="my-account-settings"
    >
      <div className="my-account-settings__header">
        <Heading level={3} noMargin>
          {t('my-account.settings')}
        </Heading>
      </div>

      <div className="my-account-settings__wrapper">
        {myAccountSettingsBlock &&
          Children.toArray(
            // eslint-disable-next-line array-callback-return
            myAccountSettingsBlock.map((settingsBlock: SettingsBlock) => {
              switch (settingsBlock.myAccountSettingsOption) {
                case MyAccountSettingsOption.securitySettings:
                  return (
                    <>
                      <div className="my-account-settings__content">
                        <Heading level={6} noMargin className="my-account-settings__section-heading">
                          {t('my-account.home.settings.security')}
                        </Heading>

                        <AccountTile noPadding>
                          <AccountTileListItem
                            text={t('my-account.home.settings.security.change-password')}
                            onClick={handleChangePassword}
                          />
                          <AccountTileListItem
                            text={t('my-account.home.settings.security.cookie-preferences')}
                            onClick={openCookiePreferences}
                          />
                        </AccountTile>
                      </div>
                    </>
                  );
                case MyAccountSettingsOption.timeZoneSettings:
                  return (
                    <div className="my-account-settings__content">
                      <Heading level={6} noMargin className="my-account-settings__section-heading">
                        {t('my-account.home.settings.time-zone')}
                      </Heading>
                      <AccountTile noPadding>
                        <AccountTileListItem text={`${town}, GMT ${gmt}:00`} />
                        <AccountTileListItem text={t('my-account.home.settings.time-zone.caution')} />
                      </AccountTile>
                    </div>
                  );
                case MyAccountSettingsOption.kambiOddsFormatSettings:
                  return (
                    <div className="my-account-settings__content">
                      <Heading level={6} noMargin className="my-account-settings__section-heading">
                        {t('my-account.home.settings.odds-format')}
                      </Heading>
                      <AccountTile>
                        <KambiOddsFormat />
                      </AccountTile>
                    </div>
                  );
                case MyAccountSettingsOption.communicationsSettings:
                  return (
                    <div className="my-account-settings__content">
                      <Heading className="my-account-settings__section-heading" noMargin level={6}>
                        {t('my-account.home.settings.communication')}
                      </Heading>
                      <AccountTile>
                        <AccountDetails
                          isMarketingSettings
                          onError={(): void => handleUpdateFailure('marketing-details')}
                          onUpdate={(): void => handleUpdateSuccess('marketing-details')}
                          SummaryComponent={AccountDetailsSummary}
                          EditModalComponent={AccountDetailsEditModal}
                          editMode="modal"
                          editTitle={t('my-account.home.settings.communication.settings')}
                          id="marketing-details"
                          fieldSets={[
                            {
                              id: 'marketing-details-fieldset',
                              label: t('my-account.home.settings.communication.preference'),
                              labelSrOnly: true,
                              editDescription: t('my-account.home.settings.communication.edit.description'),
                              fields: [
                                {
                                  name: 'toggleAllMarketing',
                                  columns: 12,
                                  overviewColumns: 12,
                                  hidden: false,
                                },
                                {
                                  name: 'optInEmail',
                                  columns: 12,
                                  overviewColumns: 12,
                                  hidden: false,
                                },
                                {
                                  name: 'optInSms',
                                  columns: 12,
                                  overviewColumns: 12,
                                  hidden: false,
                                },
                                {
                                  name: 'optInTelephone',
                                  columns: 12,
                                  overviewColumns: 12,
                                  hidden: false,
                                },
                              ],
                            },
                          ]}
                        />
                      </AccountTile>
                    </div>
                  );
                case MyAccountSettingsOption.otherSettings:
                  return (
                    <div className="my-account-settings__content">
                      <Heading level={6} noMargin className="my-account-settings__section-heading">
                        {settingsBlock.myAccountSettingsSubTitle}
                      </Heading>

                      <AccountTile className="my-account-settings__tile">
                        <AutoWithdrawal myAccountSettingsAutoWithdrawalIconPath="" />
                      </AccountTile>
                    </div>
                  );
              }
            })
          )}
      </div>

      {cookiePreferencesOpen && <CookiesPreferences onClose={closeCookiePreferences} />}
    </AccountPageWrapper>
  );
};

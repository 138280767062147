import React, { FC } from 'react';
import noop from 'lodash/noop';
import { GamesCarouselProps, TickerComponent } from '../GamesCarousel';
import { GameCard } from '../GameCard';
import { useAssetLoadedListener } from '../../../hooks/helperHooks';
import { GamesListProps, GamesListUIComponentProps } from '../GamesList';
import { Game } from '../games.model';
import { applyWideBanner, renderWideTileCarousel } from '../../../utils/wide-tile-carousel';
import { Spinner } from '../../shared/Spinner';
import { Heading } from '../../shared/Heading';
import { joinStrings } from '../../../utils/string';
import { GameCardContainerType } from '../GameCard/GameCard.types';
import './styles';

const CarouselCard: FC<
  GamesListUIComponentProps &
    GamesCarouselProps & {
      game: Game;
      containerElementWidth: number;
      slideIndex: number;
    }
> = ({
  containerElementWidth,
  size,
  fallbackImageAltText,
  showGameName,
  disableGameMenu,
  fallbackImageUrl,
  game,
  gameCardContainerType = GameCardContainerType.Wide,
  disabled,
  onViewGameInfo = noop,
  onPlayDemo = noop,
  onPlayGame = noop,
  gameLabel,
}) => {
  return (
    <GameCard
      containerElementWidth={containerElementWidth}
      size={size}
      containerType={gameCardContainerType}
      key={game.gameId}
      showGameName={showGameName}
      gameUrl=""
      game={game}
      disableMenu={disableGameMenu}
      fallbackImageAltText={fallbackImageAltText}
      fallbackImageUrl={fallbackImageUrl}
      onViewGameInfo={(): void => onViewGameInfo(game)}
      onPlayDemo={(): void => onPlayDemo(game)}
      onPlayGame={(): void => onPlayGame(game)}
      disabled={disabled}
      gameLabel={gameLabel ? 'label simple' : ''}
      showDisplayName={false}
    />
  );
};

export const MobileGamesCarousel: FC<
  GamesListProps &
    GamesListUIComponentProps &
    GamesCarouselProps & {
      containerElementWidth: number;
    }
> = (props) => {
  const {
    games,
    title,
    containerElementWidth,
    subtitle,
    showDisplayName,
    loading,
    applyBanner,
    isBanner,
    rowsNumber,
    showJackpotHeroBanner,
    jackpotImageForMobile,
    showDots,
  } = props;

  const slides = (games as Game[][]).map((slide) => {
    return slide.map((game, i) => {
      return (
        <CarouselCard
          {...props}
          rowsNumber={showJackpotHeroBanner ? 1 : rowsNumber}
          key={game.gameId}
          slideIndex={i}
          containerElementWidth={containerElementWidth}
          game={game}
          showDisplayName={showDisplayName}
          gameLabel={props.recent}
        />
      );
    });
  });

  const groupedBanner = applyBanner && applyBanner(slides);

  const { banner, slidesUnderBanner, slidesWithoutBanner } = applyWideBanner(groupedBanner);

  const { assetLoaded, onAssetLoaded } = useAssetLoadedListener([jackpotImageForMobile]);

  return loading ? (
    <Spinner />
  ) : slides && slides.length ? (
    <div>
      <section>
        {title && (
          <Heading data-testid="carousel-wrapper-title" className="carousel-wrapper__title" level={4}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <p data-testid="carousel-wrapper-subtitle" className="carousel-wrapper__subtitle">
            {subtitle}
          </p>
        )}
      </section>
      <div className="mobile-games-carousel">
        {jackpotImageForMobile && showJackpotHeroBanner && (
          <div className={joinStrings(['games-list__jackpot-banner', !showDots && 'games-list__jackpot-banner--dots'])}>
            <img
              className={joinStrings([
                'games-list__jackpot-banner-img',
                !assetLoaded &&
                  'games-list__jackpot-banner-img--skeleton games-list__jackpot-banner-img--skeleton--animated',
              ])}
              src={jackpotImageForMobile}
              alt="Mobile jackpot banner"
              onLoad={onAssetLoaded}
            />
            <TickerComponent {...props} />
          </div>
        )}
        <div className="mobile-games-carousel__slider">
          {!showJackpotHeroBanner && isBanner
            ? slidesUnderBanner &&
              renderWideTileCarousel({
                isBanner,
                slidesWithoutBanner,
                slidesUnderBanner,
                banner,
              })
            : slides.map((slide, index) => {
                return (
                  <div key={index} className="game-cards">
                    {slide}
                  </div>
                );
              })}

          {showJackpotHeroBanner &&
            slides.map((slide, index) => {
              return (
                <div key={index} className="game-cards">
                  {slide}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  ) : null;
};

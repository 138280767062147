import React, { FC, Fragment, ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { APP_URLS, locale } from '../../consts';
import { useDocumentScrollThrottled } from '../../hooks/scroll-throttled';
import {
  MediaBreakpoint,
  Orientation,
  useBreakpointInfo,
  useDeviceInfo,
  useOrientation,
} from '../../hooks/useDeviceInfo';
import { useSiteMenuGetQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/site-menu-get.query.generated';
import { Endpoint } from '../../models/api.model';
import { isNavigationExpanded, selectIsSearchBarOpen } from '../../state/app';
import { setIsNavigationExpanded, setIsSearchBarOpen } from '../../state/app/reducers';
import { ITransformedNavigationData, transformNavigationData } from '../../utils/navigation';
import { UrlOptions, isHidden } from '../../utils/set-url-storage';
import { joinStrings } from '../../utils/string';
import { SearchBar } from '../Header/SearchBar';
import { ProductType } from '../Layout/Promotions/PromotionsList/types';
import { ConditionalWrapper } from '../shared/ConditionalWrapper';
import { IconButton } from '../shared/IconButton';
import { Paragraph } from '../shared/Paragraph';
import { Spinner } from '../shared/Spinner';
import { CategoryNavigation } from './CategoryNavigation';
import { NavigationDrawer } from './NavigationDrawer';
import { NavigationLink, NavigationLinkProps } from './NavigationLink';
import { NavigationLogOutLink } from './NavigationLogOutLink';
import { preventScrollInIos } from './preventIosScroll-helper';
import { ProductTabs } from './ProductTabs/ProductTabs';
import { PromotionCategory } from './PromotionsCategory';
import { CategoryOfUsers, ProductCategoryLink, Promotions } from './types';
import { NavSearchIcon } from '../shared/SVG/Icons';
import './styles';

type NavigationProps = {
  children?: React.ReactNode;
  paddingDisabled?: boolean;
  gridClassNames?: string;
  navLinks?: NavigationLinkPropsExtended[];
  categories?: ProductCategoryLink[];
  promotions?: Promotions;
  isNavHidden?: boolean;
  isDarkBackground?: boolean;
};

export type PromotionCategoriesProps = {
  id: string;
  title: string;
  icon: string;
  activeIcon: string;
};

export type ExtendedPromotionCategories = {
  active: boolean;
} & PromotionCategoriesProps;

export interface NavigationLinkPropsExtended extends Omit<NavigationLinkProps, 'isExpanded'> {
  dividerAfter?: boolean;
  categoryTitle?: string;
}

const wrapWithDivIfHasClassNames = (classNames: string, children: ReactNode): ReactNode => {
  if (!classNames) {
    return children;
  }

  return <div className={classNames}>{children}</div>;
};

// TODO: update this component
export const Navigation: FC<NavigationProps> = ({
  children,
  paddingDisabled,
  navLinks,
  categories,
  promotions,
  gridClassNames = 'gridClassNames',
  isDarkBackground,
  isNavHidden = false,
}) => {
  // TODO: Current breakpoints don't match up with scss breakpoints
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLessThanTablet = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);
  const isIos = useDeviceInfo().isIos;
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const isIosNotLandscape = useDeviceInfo().isIos && !isLandscape;

  const navigationExpanded = useSelector(isNavigationExpanded);
  const isSearchBarOpen = useSelector(selectIsSearchBarOpen);

  const isTabletExpanded = isLessThanTablet || navigationExpanded;
  const isNavVisible = isNavHidden && !isLessThanTablet ? navigationExpanded : true;
  const isUserAuthenticated = auth?.isAuthenticated;
  const showNotificationBar = false;
  const isDisabledNavigationChildren = true;
  // TODO: Remove auth mock
  const showPromotionsCategories = isUserAuthenticated;

  const filteredPromoCategoriesInit = [
    {
      id: 'test',
      products: ['casino'],
      title: 'Test Promotions',
      icon: 'test',
      activeIcon: 'test',
      active: true,
    },
    {
      id: 'test',
      products: ['sports', 'casino'],
      title: 'Test New Promotions',
      icon: 'test',
      activeIcon: 'test',
      active: true,
    },
  ];

  const [hideSubnav, setHideSubnav] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNav, setShowNav] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const initialNavData: ITransformedNavigationData = {
    menuItems: [],
    lowerMenuItems: [],
  };
  const [navigationData, setNavigationData] = useState<ITransformedNavigationData>(initialNavData);

  const [filteredPromoCategories, setFilteredPromoCategories] = useState(filteredPromoCategoriesInit);
  const { loading } = useSiteMenuGetQuery({
    variables: {
      language: locale,
    },
    onCompleted: (data) => {
      const transformedSiteMenu = transformNavigationData(data);

      setNavigationData(transformedSiteMenu);
    },
    onError: (error) => console.error(error),
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  useLayoutEffect(() => {
    if (isNavHidden) {
      dispatch(setIsNavigationExpanded(false));
    }
  }, [dispatch, isNavHidden]);

  useEffect(() => {
    isIos && preventScrollInIos(false, scrollPosition, setScrollPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIos]);

  const onSearchIconClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      event.preventDefault();

      dispatch(setIsSearchBarOpen(true));
    },
    [dispatch]
  );

  const handleCloseNavigationDrawer = (): void => {
    dispatch(setIsNavigationExpanded(false));
  };

  const setActiveSportCategory = useCallback(
    (id: string) => {
      const newPromoCategories = filteredPromoCategories.map((category) => {
        category.id === id ? (category.active = true) : (category.active = false);

        return category;
      });

      setFilteredPromoCategories(newPromoCategories);
    },
    [filteredPromoCategories]
  );

  const filterCategoryToProduct = useCallback(
    (productName: ProductType) => {
      const filtedPromoCategories = filteredPromoCategories.filter((event) => event.products.includes(productName));

      setFilteredPromoCategories(filtedPromoCategories);
    },
    [filteredPromoCategories]
  );

  const { AllUser, Authenticated, AuthenticatedInvitedVip, AuthenticatedNonVip, AuthenticatedVip, Unauthenticated } =
    CategoryOfUsers;

  const mapNavLinks = (
    navItems?: NavigationLinkPropsExtended[],
    title?: string | undefined | null,
    isNavMenu?: boolean
  ): ReactNode => {
    return (
      <ConditionalWrapper
        condition={Boolean(isNavMenu) && !isUserAuthenticated}
        wrapper={(children): JSX.Element => (
          <div
            className={joinStrings([
              'navigation-link-top-wrapper',
              showNotificationBar && isLessThanTablet && 'navigation-link-top-wrapper--with-verify',
            ])}
          >
            {children}
          </div>
        )}
      >
        <div>
          {title && (
            <Paragraph
              className={joinStrings([
                'navigation__category-title',
                isTabletExpanded && 'navigation__category-title--expanded',
              ])}
            >
              {title}
            </Paragraph>
          )}
          {navItems?.map((navLink) => {
            const { dividerAfter, ...navLinkProps } = navLink;
            const isHomePage = navLinkProps.to === APP_URLS.home;
            const isMyAccount = String(navLinkProps?.to).includes(APP_URLS.myAccount.home);

            const checkShowNavlink = (): boolean | undefined => {
              switch (navLinkProps?.showForUsers) {
                case AllUser:
                  return true;
                case Authenticated:
                  return isUserAuthenticated;
                case AuthenticatedVip:
                  return isUserAuthenticated && true;
                case AuthenticatedInvitedVip:
                  return isUserAuthenticated && true;
                case AuthenticatedNonVip:
                  return isUserAuthenticated;
                case Unauthenticated:
                  return !isUserAuthenticated;
              }
            };

            return (
              <div key={navLinkProps.name + navLinkProps.to}>
                {checkShowNavlink() ? (
                  <Fragment>
                    <NavigationLink
                      {...navLinkProps}
                      isExpanded={isTabletExpanded}
                      active={
                        isHomePage
                          ? location.pathname === String(navLinkProps.to)
                          : location.pathname.includes(String(navLinkProps.to))
                      }
                      state={isMyAccount ? { previousLocation: location } : undefined}
                    />
                    {dividerAfter && <div className="navigation__divider" />}
                  </Fragment>
                ) : null}
              </div>
            );
          })}
        </div>
      </ConditionalWrapper>
    );
  };

  function renderNav(isLoading: boolean): JSX.Element | null {
    return !isHidden(UrlOptions.hideNavigation) && showNav && isNavVisible ? (
      <nav
        className={joinStrings(['navigation', !paddingDisabled && `navigation--with-padding`])}
        aria-label="navigation"
      >
        {isLoading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <div
            className={joinStrings([
              'navigation__navigation_links',
              isUserAuthenticated && 'navigation__navigation_links--authenticated',
              isIosNotLandscape && 'navigation__navigation_links--ios',
              showNotificationBar && 'navigation__navigation_links--notification',
            ])}
          >
            <div className="navigation__navigation_links-scrollbar">
              {mapNavLinks(navLinks, undefined, true)}
              {mapNavLinks(navigationData?.menuItems, undefined, false)}
              {mapNavLinks(navigationData.lowerMenuItems, undefined, false)}
            </div>
            <div className="navigation__navigation_links-logout">{isUserAuthenticated && <NavigationLogOutLink />}</div>
          </div>
        )}
      </nav>
    ) : null;
  }

  // Scroll detection for hidden subnavigation
  useDocumentScrollThrottled((currentScrollTop) => {
    const isMinimumScrolled = currentScrollTop > 0;

    setHideSubnav(isMinimumScrolled);
  });

  return (
    <>
      {isLessThanTablet ? (
        <NavigationDrawer isOpen={navigationExpanded} onClose={handleCloseNavigationDrawer}>
          {renderNav(loading)}
        </NavigationDrawer>
      ) : (
        renderNav(loading)
      )}

      <div className="navigation__content-container">
        {Boolean(promotions) && (
          <>
            <ProductTabs
              promotionTabs={promotions?.tabs || []}
              isEnabledProductCasino
              isSportProductTab={false}
              isEnabledProductSports
              filterCategoryToProduct={filterCategoryToProduct}
            />
            {showPromotionsCategories && (
              <div className="navigation__pills">
                <PromotionCategory
                  categories={promotions?.categories || []}
                  setActivCategory={setActiveSportCategory}
                />
              </div>
            )}
          </>
        )}
        {categories?.length ? (
          <>
            <div
              className={joinStrings([
                'navigation__subnav-wrapper',
                hideSubnav && 'navigation__subnav-wrapper--hidden',
              ])}
            >
              {isLessThanTablet && !isSearchBarOpen ? (
                <IconButton
                  className="navigation__subnav-search"
                  onClick={onSearchIconClick}
                  aria-label="search-icon"
                  icon={<NavSearchIcon />}
                  size="large"
                  color="inherit"
                />
              ) : (
                <div
                  className={joinStrings([
                    'navigation__subnav-search-bar',
                    hideSubnav && 'navigation__subnav-search-bar--hidden',
                  ])}
                >
                  <SearchBar isUsedOverlay={true} />
                </div>
              )}
              <div className={joinStrings(['navigation__subnav', hideSubnav && 'navigation__subnav--hidden'])}>
                <CategoryNavigation enabled categories={categories} />
              </div>
            </div>
          </>
        ) : null}
        <div
          className={joinStrings([
            'navigation__children',
            !paddingDisabled && `navigation__children--with-padding`,
            isDisabledNavigationChildren && 'navigation__children--disabled',
            isDarkBackground && 'navigation__children--dark',
          ])}
        >
          {wrapWithDivIfHasClassNames(gridClassNames, children)}
        </div>
      </div>
    </>
  );
};

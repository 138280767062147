import React, { FC } from 'react';
import { Button } from '../../../../shared/Button';
import { Icon } from '../../../../shared/Icon';
import { Paragraph } from '../../../../shared/Paragraph';
import './AccountTileRealityCheckItem.scss';

type AccountTileRealityCheckItemProps = {
  text: string;
  onOpenEditModal: () => void;
};

export const AccountTileRealityCheckItem: FC<AccountTileRealityCheckItemProps> = ({ text, onOpenEditModal }) => {
  return (
    <div className="account-tile-list-item reality-check-list-item">
      <div className="account-tile-list-item__content">
        <Paragraph className="account-tile-list-item__text" size="md" highlight noMargin>
          {text}
        </Paragraph>

        <Button type="button" variant="text" onClick={onOpenEditModal} aria-label="Edit">
          <Icon variant="Edit" />
        </Button>
      </div>
    </div>
  );
};

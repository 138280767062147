import React, {
  FunctionComponent,
  forwardRef,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/snackbar';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { useUserContext } from '../../../providers/UserProvider';
import { joinStrings } from '../../../utils/string';
import { Link } from '../../shared/Link';
import { Paragraph } from '../../shared/Paragraph';
import { PopUpHandler } from '../../shared/PopupHandler';
import { Prompt } from '../../shared/Prompt';
import { GameItemUIProps } from '../GamesList';
import { getGameUrl } from '../helper';
import { GameCardContext, GameCardContextProps } from './GameCard.context';
import { GameCardContainerType } from './GameCard.types';
import { GameCardBasic } from './GameCardBasic';
import { GameCardActions, GameCardDetails } from './GameCardDetails';
import { GameCardOverlay } from './GameCardOverlay';
import { Game } from '../games.model';
import { storageRoute } from '../../../utils/storage';
import { getRedirectUri } from '../../Authentification/helper';
import './GameCard.scss';

export interface GameCardProps extends GameItemUIProps {
  /**
   * Card container styling.
   */
  containerType?: GameCardContainerType;
  /**
   * Disables card from being interacted with.
   */
  disabled?: boolean;
  /**
   * Show or hide game details by default.
   */
  showDetails?: boolean;
  /**
   * Show details on hover.
   */
  showDetailsOnHover?: boolean;
  /**
   * Delay between hovering on an item and showing the details.  Only applies if showDetailsOnHover is true.
   */
  showDetailsInterval?: number;
  /**
   * Whether to display game details (tags etc)
   */
  displayDetails?: boolean;
  /**
   * Whether to show the GameCardActions component
   */
  showActions?: boolean;
  /**
   * Used to set a custom transform origin if needed (carousel)
   */
  customTransformOrigin?: string;
  /**
   * Card container width - used to help absolute position zoomed in game cards.
   */
  containerElementWidth?: number;
  /**
   * focus on image card button on load.
   */
  focusOnLoad?: boolean;
  /**
   * Show display name
   */
  showDisplayName?: boolean;
  /**
   * Use a router link for navigation, defaults to true
   */
  routerLink?: boolean;
  /**
   * label on game card
   */
  gameLabel?: string;
  /**
   * disable game on specific platform
   */
  isGameExcluded?: boolean;
  banner?: boolean;
}

export const GameCardContent = forwardRef<
  HTMLDivElement,
  GameCardProps & {
    realLaunchUrl: string;
    enablePlayOnClick?: boolean;
    isPlayLoading?: boolean;
    handlePlayLoading: (arg: boolean) => void;
    closePopUpHandler?: () => void;
  }
>(
  (
    {
      game,
      fallbackImageUrl,
      fallbackImageAltText,
      containerType = GameCardContainerType.Box,
      disabled = false,
      displayDetails = false,
      enablePlayOnClick = false,
      onViewGameInfo,
      onPlayGame,
      onPlayDemo,
      showActions,
      realLaunchUrl,
      focusOnLoad = false,
      showDisplayName = true,
      routerLink = true,
      gameLabel,
      banner,
      isPlayLoading,
      handlePlayLoading,
      closePopUpHandler,
      ...props
    },
    ref
  ) => {
    const location = useLocation();
    const auth = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { addSnack } = useSnackbar();
    const { userProfileId } = useUserContext();
    const playerId = userProfileId || (auth.user?.profile?.user_profile_id as string);
    const isUserAuthenticated = auth?.isAuthenticated;
    const { gameImage, displayName, friendlyUrlName } = game;
    const cardRef = useRef<HTMLDivElement>(null);
    const cardImage = {
      url: gameImage.imgUrl,
      alt: displayName,
    };

    const { dismissCard } = useContext(GameCardContext);

    const memoizedHandlePlayOnImgClick = useCallback(
      async (e: React.MouseEvent | React.KeyboardEvent) => {
        if (isPlayLoading) {
          return;
        }

        onPlayGame?.();
        const gameUrl = `/game-details/${friendlyUrlName}/real`;

        if (isUserAuthenticated) {
          try {
            if (auth.user?.expired) {
              await auth.signinSilent();
            }

            handlePlayLoading(true);

            navigate(gameUrl);

            storageRoute.set({
              value: location.pathname,
            });

            return;
          } catch (error) {
            console.error('retrieveGameLaunchUrl error: ', error);
            addSnack({
              type: 'error',
              message: "Oops, something's gone wrong",
            });
          } finally {
            handlePlayLoading(false);
          }
        }

        e.preventDefault();
        dismissCard();
        auth.signinRedirect({ redirect_uri: getRedirectUri(gameUrl) });
      },
      [
        addSnack,
        auth,
        dismissCard,
        dispatch,
        friendlyUrlName,
        handlePlayLoading,
        isPlayLoading,
        isUserAuthenticated,
        navigate,
        onPlayGame,
        playerId,
      ]
    );

    useLayoutEffect(() => {
      if (cardRef.current && focusOnLoad) {
        const buttons = cardRef.current.querySelectorAll('div[role="button"]') as NodeListOf<HTMLButtonElement>;

        buttons[0] && buttons[0].focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardRef.current, focusOnLoad]);

    return (
      <div className="game-card-content" ref={cardRef}>
        <div className="game-card-content__info">
          <div className="game-card-content__inner" ref={ref}>
            <GameCardBasic
              containerType={containerType}
              disabled={disabled}
              image={cardImage}
              displayName={displayName}
              showDisplayName={showDisplayName}
              fallbackImageUrl={fallbackImageUrl}
              fallbackImageAltText={fallbackImageAltText}
              {...(!disabled && enablePlayOnClick
                ? {
                    onClick: memoizedHandlePlayOnImgClick,
                  }
                : {})}
              gameLabel={gameLabel}
            />

            {displayDetails && !banner && (
              <div className="game-card__wrapper">
                <GameCardDetails
                  game={game as Game}
                  isUnavailable={false}
                  showActions={showActions}
                  disabled={disabled}
                  onInfoClick={onViewGameInfo}
                  onPlayGame={onPlayGame}
                  onPlayDemo={onPlayDemo}
                  routerLink={routerLink}
                  handlePlayLoading={handlePlayLoading}
                  isPlayLoading={isPlayLoading}
                  onClose={closePopUpHandler}
                />

                {closePopUpHandler && (
                  <GameCardActions
                    {...props}
                    game={game as Game}
                    handlePlayLoading={handlePlayLoading}
                    isPlayLoading={isPlayLoading}
                    disabled={disabled}
                    isUnavailable={false}
                    onClose={closePopUpHandler}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

GameCardContent.displayName = 'CardContent';

export const GameCard: FunctionComponent<GameCardProps> = (props) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { isMobileDevice } = useDeviceInfo();

  const [zoom, setZoom] = useState(false);
  const [wiggle, setWiggle] = useState(false);
  const [isZooming, setIsZooming] = useState(false);
  const [showExcludedDrawer, setShowExcludedDrawer] = useState(false);

  const isOpen = useMemo(() => {
    return isZooming;
  }, [isZooming]);

  const { game, disabled = false, banner, gameLabel } = props;
  const { displayName, friendlyUrlName } = game;
  const realLaunchUrl = getGameUrl(friendlyUrlName, 'real');

  let wiggleTimeout: NodeJS.Timeout;
  const [isPlayLoading, setIsPlayLoading] = useState<boolean>(false);

  const onCloseExcludedDrawer = (): void => setShowExcludedDrawer(false);

  const handleWiggle = (delay = 700): void => {
    setWiggle(true);
    clearTimeout(wiggleTimeout);
    wiggleTimeout = setTimeout(() => {
      setWiggle(false);
    }, delay);
  };

  const handleGameTileClick = (): void => {
    handleWiggle();
  };

  const cancelZoom = (): void => {
    setZoom(false);
    setIsZooming(false);
  };

  const cancelZoomAndFocus = (): void => {
    cancelZoom();
    setIsPlayLoading(false);
    ref.current && ref.current.focus();
  };

  const contextValue: GameCardContextProps = {
    dismissCard: () => {
      setZoom(false);
    },
  };

  const onClick = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.preventDefault();

    disabled ? handleGameTileClick() : setIsZooming(!isZooming);
  };

  const closePopUpHandler = (): void => {
    setIsZooming(false);
    setIsPlayLoading(false);
  };

  const onMouseEnter = (): void => {
    const currentElement = contentRef.current?.getBoundingClientRect();
    const header = document.querySelector('.header__nav');
    const headerHeight = header?.getBoundingClientRect().height || 0;

    if (
      currentElement &&
      document.documentElement.clientHeight >= currentElement?.bottom &&
      currentElement.top >= headerHeight &&
      displayName !== 'skeleton-tile'
    ) {
      setIsZooming(true);
    }
  };

  const onKeyDown = (e: { key: string; target: HTMLDivElement | EventTarget; shiftKey: boolean }): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      setIsZooming(true);
    } else if (
      e.key === 'Escape' ||
      (e.key === 'Tab' && (e.target as HTMLDivElement).className.includes('primary')) ||
      (e.shiftKey && e.key === 'Tab' && (e.target as HTMLDivElement).className.includes('container--button'))
    ) {
      cancelZoomAndFocus();
    }
  };

  const handlePlayLoading = (arg: boolean): void => {
    setIsPlayLoading(arg); // Added arg to pass false in some specific cases
  };

  return (
    <GameCardContext.Provider value={contextValue}>
      <a
        href={getGameUrl(friendlyUrlName)}
        ref={ref}
        tabIndex={0}
        role="button"
        className={joinStrings(['game-card', wiggle && `game-card--wiggle`])}
        {...(isMobileDevice
          ? {
              onKeyDown: (e) => onClick(e),
              onClick,
            }
          : {
              onMouseEnter,
              onMouseLeave: cancelZoom,
              'aria-label': displayName,
              onKeyDown,
              onClick: (e: React.MouseEvent | React.KeyboardEvent): void => {
                e.preventDefault();
                handleGameTileClick();
              },
            })}
      >
        <div ref={contentRef}>
          <GameCardContent
            {...props}
            gameLabel={gameLabel}
            disabled={disabled}
            realLaunchUrl={realLaunchUrl}
            isPlayLoading={isPlayLoading}
            handlePlayLoading={handlePlayLoading}
          />
        </div>
        {!isMobileDevice && isZooming && !banner && (
          <GameCardOverlay
            zoom={zoom}
            props={props}
            innerRef={ref}
            setZoom={setZoom}
            isZooming={isZooming}
            gameLabel={gameLabel}
            cancelZoom={cancelZoom}
            isDisabled={disabled}
            contentRef={contentRef}
            realLaunchUrl={realLaunchUrl}
            isPlayLoading={isPlayLoading}
            handlePlayLoading={handlePlayLoading}
          />
        )}
        {isMobileDevice && isOpen && (
          <PopUpHandler
            modalProps={{
              'aria-label': 'game-card-popup-label',
              className: 'game-card-popup',
            }}
            drawerProps={{
              className: 'game-card-popup',
              mode: 'dark',
            }}
            onClose={cancelZoom}
            modalBodyOverflow={false}
            isOpen={isMobileDevice && isOpen}
          >
            <GameCardContent
              {...props}
              gameLabel={gameLabel}
              isPlayLoading={isPlayLoading}
              containerType={GameCardContainerType.Wide}
              displayDetails
              showActions={false}
              enablePlayOnClick
              realLaunchUrl={realLaunchUrl}
              disabled={disabled}
              handlePlayLoading={handlePlayLoading}
              closePopUpHandler={closePopUpHandler}
            />
          </PopUpHandler>
        )}
        {showExcludedDrawer && (
          <Prompt
            className="game-card-excluded-drawer"
            title="Heading"
            show={showExcludedDrawer}
            onClose={onCloseExcludedDrawer}
          >
            <Paragraph>
              Unfortunately this game is not yet availble in the app
              <Link href="/excluded-drawer-link-url" buttonStyle={{ variant: 'text', size: 'auto' }}>
                Site link
              </Link>
            </Paragraph>
          </Prompt>
        )}
      </a>
    </GameCardContext.Provider>
  );
};

import { GameInfoProps } from './GameInfo';
import { HeroBannerSlide } from '../HeroBannerCarousel';
import { PaginationParams } from '../../models/api.model';
import { GameCardContainerType } from './GameCard/GameCard.types';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type PaginatedResponse<T> = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
  items: T[];
};

export type GameJackpot = {
  id: string;
  provider: string;
  amount: number;

  currency?: string;
  status?: string;
  seed?: number;
  isHeating?: number;
  jackpotType?: string;
  startTime?: number;
  endTime?: number;
  lastWinAmount?: number;
  velocity?: number;
};

export type JackpotFeed = {
  totalJackpotAmount: number;
  jackpots: GameJackpot[];
};

export const defaultRelatedGamesCategory = 'default-related-games';

export enum GameTag {
  Unavailable = 'unavailable',
  New = 'new',
  Limited = 'limited',
  Featured = 'featured',
  LastChance = 'last-chance',
}

export interface GameImage {
  alt: string;
  label: string;
  url: string;
}

export enum GameChannel {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export interface Game {
  gameId: string;
  categoryName: string;
  rating?: number | null;
  rtp?: string | null;
  paylines?: string | null;
  gameplayBackground: {
    __typename?: string;
    imgUrl: string;
    width?: number | null;
    height?: number | null;
  };
  bannerImage: {
    __typename?: string;
    imgUrl: string;
    width?: number | null;
    height?: number | null;
  };
  maxBet?: string | null;
  minBet?: string | null;
  maxRtp?: string | null;
  minRtp?: string | null;
  tags?: Array<{
    __typename?: string;
    name?: string | null;
  } | null> | null;
  category?: {
    __typename?: string;
    title?: string | null;
    description?: string | null;
    slug?: string | null;
  } | null;
  displayName: string;
  shortDescription?: string;
  longDescription?: string;
  rules?: string;
  friendlyUrlName: string;
  features: Array<{
    thumbnail: {
      width?: number | null;
      height?: number | null;
      title: string;
      imgUrl: string;
    };
    displayName: string;
    description: string;
  }>;
  gameImage: {
    __typename?: string;
    imgUrl: string;
    width?: number | null;
    height?: number | null;
  };
  channels: {
    name: 'Desktop' | 'Mobile';
    channel: GameChannel;
    width: number | undefined;
    height: number;
    isAvailableForDemoPlay: boolean;
  }[];
  provider?: {
    mediatorId: string;
  };
}

export interface ILobbyBannerCollection {
  banners: HeroBannerSlide[];
  staticBanner: HeroBannerSlide;
}

export interface LobbyItem {
  slug: string;
  icon: string;
  title: string;
  games: Game[] | undefined;
  carouselType: GameCardContainerType;
  description: string;
  internalName: string;
}

export interface LobbiesData {
  lobbyItems: LobbyItem[];
  showCategories: boolean;
  generalDescription: string;
  bannerCollection: ILobbyBannerCollection | null;
}

export interface GameLaunch {
  launchUrl: string;
}

export interface GameListQueryParams extends PaginationParams {
  category?: string;
  tag?: string[];
  provider?: string | null;
  name?: string;
}

export interface GameLaunchParams {
  autoLaunch: false;
  channel: 'mobile' | 'desktop' | 'tablet';
  currencyCode: string;
  isDemo: boolean;
  language: string;
  sourceUrl: string;
  userAgent: string;
}

interface CommonGameSession {
  sessionID: Nullable<string>;
  sessionStart: Nullable<string>;
  sessionEndedAt: Nullable<string>;
  sessionLength?: number;
  sessionLossLimit?: number;
  sessionCoolDown?: number;
  sessionReminderRequired?: boolean;
  sessionReminderInterval?: number;
  nextSessionReminder?: string;
  confirmSessionReminderUrl: Nullable<string>;
  transactionHistoryUrl: string;
  wins: number;
  losses: number;
  lossLimitReached?: boolean;
  dailyStakesLimit?: number;
  dailyLossesLimit?: number;
  weeklyStakesLimit?: number;
  weeklyLossesLimit?: number;
  monthlyStakesLimit?: number;
  monthlyLossesLimit?: number;
}
export interface GameSession extends CommonGameSession {
  sessionState: {
    active: boolean;
    startedAt: Nullable<string>;
    endedAt: Nullable<string>;
  };
}

export interface ComplianceGameSession extends CommonGameSession {
  categories: string[];
  siteCode: string;
  autoWithdrawalThreshold: number;
  pendingConfirmation: boolean;
  maximumStake: number;
  gameSessionID: string;
}

export enum Statuses {
  NotStarted = 'NotStarted',
  Running = 'Running',
  Completed = 'Complete',
}

export type GameInfoContainerProps = Omit<GameInfoProps, 'jackpot'> & {
  jackpotsRefreshIntervalSeconds?: number;
  /**
   * Number of columns to display game overview as
   */
  gameOverviewColumns?: 1 | 2;
};

export type RelatedGamesProps = {
  game?: Game;
  /**
   * Fallback image url for related games when no relevant images can be found
   */
  fallbackImageUrl: string;
  /**
   * Alt text if game image fails to load.
   */
  fallbackImageAltText: string;
  /**
   * Whether to show all games link.  Defaults to false.
   */
  showAllGamesLink?: boolean;
  /**
   * The initial number of games to request and display.
   */
  defaultNumberOfGames?: number;
};

export type CasinoProps = {
  gameEnabled: boolean;
};

export type SimilarGamesConfig = {
  take?: number;
  category?: string;
};

export type GameFeaturesConfig = {
  iconPath: string;
  featureText: string;
  gmsCustomField: string;
  tooltipText: string;
};

export type GameInfoConfig = {
  hideRTP?: boolean;
  hideNumberOfLines?: boolean;
  hideMinBet?: boolean;
  hideMaxBet?: boolean;
  hideCustomFields?: boolean;
  hideLongDescription?: boolean;
  hideRules?: boolean;
  similarGames?: SimilarGamesConfig;
  gameFeatures?: Array<GameFeaturesConfig>;
};

export interface GameItemResponse {
  __typename?: string;
  game_id?: string | null;
  rtp?: string | null;
  min_rtp?: string | null;
  max_rtp?: string | null;
  min_bet?: string | null;
  max_bet?: string | null;
  paylines?: string | null;
  translations?: Array<{
    __typename?: string;
    display_name?: string | null;
    short_description?: string | null;
    long_description?: string | null;
    rules?: string | null;
    slug?: string | null;
  } | null> | null;
  provider?: {
    mediator_id?: string | null;
  } | null;
  banner_image?: {
    __typename?: 'directus_files';
    filename_disk?: string | null;
    width?: number | null;
    height?: number | null;
    title?: string | null;
  } | null;
  gameplay_background?: {
    __typename?: string;
    filename_disk?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  game_image?: {
    __typename?: string;
    filename_disk?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  tags?: Array<{
    __typename?: string;
    game_tags_id?: {
      __typename?: string;
      translations?: Array<{ __typename?: string; name?: string | null } | null> | null;
    } | null;
  } | null> | null;
  features?: Array<{
    __typename?: string;
    game_features_id?: {
      __typename?: string;
      thumbnail?: {
        __typename?: string;
        width?: number | null;
        height?: number | null;
        title?: string | null;
        filename_disk?: string | null;
      } | null;
      translations?: Array<{
        __typename?: string;
        display_name?: string | null;
        description?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
  category?: { __typename?: string; internal_name?: string | null } | null;
}

export interface IGameCategoryResponseItem {
  __typename?: string;
  games?: Array<{
    __typename?: string;
    game_id?: {
      __typename?: string;
      game_id?: string | null;
      translations?: Array<{
        __typename?: string;
        display_name?: string | null;
        slug?: string | null;
      } | null> | null;
      game_image?: {
        __typename?: string;
        filename_disk?: string | null;
        width?: number | null;
        height?: number | null;
      } | null;
      tags?: Array<{
        __typename?: string;
        game_tags_id?: {
          __typename?: string;
          translations?: Array<{ __typename?: string; name?: string | null } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
}

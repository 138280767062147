import { createElement } from './createElement';

export const initHead = (): void => {
  const css = [
    'https://gcv2.mg-dev.ovh/betzone-current/metric-gc/preflight.css',
    'https://gcv2.mg-dev.ovh/betzone-current/metric-gc/styles.css',
    'https://gcv2.mg-dev.ovh/betzone-current/metric-gc/root-styles.css',
    'https://gcv2.mg-dev.ovh/betzone-current/metric-gc/assets/theme.css',
  ];

  css.forEach((link) => {
    createElement(
      'link',
      [
        { name: 'data-mggcv2-asset', value: 'true' },
        { name: 'href', value: link },
        { name: 'rel', value: 'stylesheet' },
      ],
      'head'
    );
  });
  createElement(
    'meta',
    [
      { name: 'data-mggcv2-asset', value: 'true' },
      { name: 'name', value: 'importmap-type' },
      { name: 'content', value: 'systemjs-importmap' },
    ],
    'head'
  );
  createElement(
    'script',
    [
      { name: 'data-mggcv2-asset', value: 'true' },
      { name: 'type', value: 'systemjs-importmap' },
      { name: 'src', value: 'https://gcv2.mg-dev.ovh/betzone-current/metric-gc/importmap.json' },
    ],
    'head'
  );
};

export const initHTML = (): void => {
  const init = document.createElement('script');

  init.innerHTML = `System.import("@metricgaming/gc-v2-root-config");`;
  init.setAttribute('id', 'mggcv2-import');
  init.setAttribute('data-mggcv2-asset', 'true');
  document.getElementsByTagName('body')[0].appendChild(init);
};

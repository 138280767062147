import { ApolloLink, createHttpLink, split } from '@apollo/client';
import { Endpoint } from '../../models/api.model';
import { authAppEndpointMiddlewareLink, authBetzoneEndpointMiddlewareLink } from './authLinks';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const appApiURL = createHttpLink({
  uri: process.env.REACT_APP_BASE_API_URL,
});

const appAPIURLWithAuth = ApolloLink.from([authAppEndpointMiddlewareLink, appApiURL]);

const appApiURLWss = new GraphQLWsLink(
  createClient({
    url: String(process.env.REACT_APP_BASE_API_URL_WSS),
  })
);

const appAPIURLWithAuthWss = ApolloLink.from([authAppEndpointMiddlewareLink, appApiURLWss]);

const betzoneDirectusApiUrl = createHttpLink({
  uri: process.env.REACT_APP_BETZONE_DIRECTUS_API_URL,
});

const betzoneDirectusAPIWithAuth = ApolloLink.from([authBetzoneEndpointMiddlewareLink, betzoneDirectusApiUrl]);

const initApiLinks = split(
  (operation) => {
    return operation.getContext().endpoint === Endpoint.appAPI || !operation.getContext().endpoint;
  },
  appAPIURLWithAuth,
  betzoneDirectusAPIWithAuth
);

export const apiLinks = split(
  (operation) => {
    const definition = getMainDefinition(operation.query);

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  appAPIURLWithAuthWss,
  initApiLinks
);

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { Button } from '../../../shared/Button';
import { Prompt } from '../../../shared/Prompt';
import { TimeTillComponent } from './TimeTillComponent';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useGameSession } from '../../../../hooks/games';
import { usePrevious } from '../../../../hooks/helperHooks';
import { storageRealityCheckTime } from '../../../../utils/storage';
import { useUserContext } from '../../../../providers/UserProvider';
import { setGameLaunchTime } from '../../../../state/game/reducers';
import { APP_URLS, GAME_INFO_PRE_BREAK_COUNTDOWN } from '../../../../consts';
import promptImg from '../../../../assets/Prompt/registration-abandon-prompt.png';
import { selectDisabledGamesData, selectGameLaunchTime } from '../../../../state/game';
import { calculateNewDate, convertMinutesToMs, getTimeInMs } from '../../../../utils/dates';

export const GameSessionInGame: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();
  const { gameTimeElapsed } = useGameSession();
  const gameLaunchTime = useSelector(selectGameLaunchTime);
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);
  const prevAreGamesDisabledState = usePrevious(areGamesDisabled);
  const [timeTillCoolDownInfo, setTimeTillCoolDownInfo] = useState({
    active: false,
    time: GAME_INFO_PRE_BREAK_COUNTDOWN,
  });
  const realityCheckTime = convertMinutesToMs(Number(storageRealityCheckTime.get(userProfileId)));
  const [gameTimePlayed, setGameTimePlayed] = useState<string | undefined>(undefined);
  const [gameBreakStartTime, setGameBreakStartTime] = useState<number | null>(null);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const onContinuePlaying = (): void => {
    const newGameBreakStartTime = calculateNewDate(new Date().toISOString(), realityCheckTime);

    setGameBreakStartTime(newGameBreakStartTime);
    setShowPrompt(false);
  };

  const onDismiss = (): void => {
    navigate(APP_URLS.home);
    dispatch(setGameLaunchTime());
  };

  const isAlmostBreak = (finishTime: number | null): boolean =>
    Boolean(finishTime && finishTime - getTimeInMs() < GAME_INFO_PRE_BREAK_COUNTDOWN);

  const isBreakNeeded = (finishTime: number | null): boolean => Boolean(finishTime && finishTime < getTimeInMs());

  const handlePause = (): void => {
    setShowPrompt(true);
    setGameTimePlayed(gameTimeElapsed);
    setTimeTillCoolDownInfo({ ...timeTillCoolDownInfo, active: false });
  };

  useEffect(() => {
    if (!timeTillCoolDownInfo.active || areGamesDisabled) return;

    addSnack({
      type: 'info',
      theme: 'dark',
      message: <TimeTillComponent timeTillCoolDown={timeTillCoolDownInfo.time} />,
      displayTime: timeTillCoolDownInfo.time,
      action: () => (
        <Button type="button" variant="text">
          Dismiss
        </Button>
      ),
    });
  }, [timeTillCoolDownInfo.active]);

  useLayoutEffect(() => {
    if (gameLaunchTime && realityCheckTime) {
      const time = calculateNewDate(gameLaunchTime, realityCheckTime);

      setGameBreakStartTime(time);
    }
  }, [gameLaunchTime]);

  useEffect(() => {
    if (!areGamesDisabled && prevAreGamesDisabledState) {
      dispatch(setGameLaunchTime());
    }
  }, [areGamesDisabled]);

  useEffect(() => {
    if (!areGamesDisabled && !showPrompt) {
      if (isAlmostBreak(gameBreakStartTime) && !timeTillCoolDownInfo.active) {
        setTimeTillCoolDownInfo({ ...timeTillCoolDownInfo, active: true });
      }

      if (isBreakNeeded(gameBreakStartTime)) {
        handlePause();
      }
    }
  }, [gameTimeElapsed, gameLaunchTime]);

  return (
    <>
      <Prompt
        cannotClose
        show={showPrompt}
        title={`You have been playing for ${gameTimePlayed}`}
        image={{
          src: promptImg,
          alt: 'Session over',
        }}
        PrimaryButton={{
          text: 'Continue playing',
          onClick: onContinuePlaying,
        }}
        SecondaryButton={{
          onClick: onDismiss,
          text: 'Got it - back to lobby',
        }}
        className="game-session-in-game__prompt"
      />
    </>
  );
};

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { APP_URLS, locale } from '../../consts';
import { Spinner } from '../shared/Spinner';
import { GamesCarouselList } from '../Games';
import { Paragraph } from '../shared/Paragraph';
import { InnerHTML } from '../shared/InnerHTML';
import { LobbiesData } from '../Games/games.model';
import { useSnackbar } from '../../hooks/snackbar';
import { useFetchGames } from '../../hooks/useFetchGames';
import { ProductCategoryLink } from '../Navigation/types';
import { selectDisabledGamesData } from '../../state/game';
import { HeroBannerCarousel } from '../HeroBannerCarousel';
import { NavigationTemplate } from '../../layouts/NavigationTemplate';
import { prepareCategories } from '../../utils/links-helper/links-helper';
import { filterLobbyData, transformLobbyData } from '../../utils/games/games.utils';
import './CasinoContent.scss';

export const CasinoContent: FC = () => {
  const { addSnack } = useSnackbar();
  const { category, subcategory } = useParams();
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);
  const [categories, setCategories] = useState<ProductCategoryLink[]>([]);
  const [lobbiesData, setLobbiesData] = useState<LobbiesData | null>(null);
  const [filteredLobbiesData, setFilteredLobbiesData] = useState<LobbiesData | null>(null);
  const { fetchLobbiesBySlug, fetchedLobbiesLoading } = useFetchGames({
    slug: category,
    language: locale,
    onLobbiesBySlugCompleted: (data) => {
      const transformedData = transformLobbyData(data);
      const cats = prepareCategories(transformedData?.lobbyItems, `${APP_URLS.gameLobby.main}/${category}`);

      setCategories(cats);

      if (!transformedData) {
        addSnack({
          type: 'error',
          message: `Oops, something's gone wrong`,
          theme: 'dark',
        });

        return;
      }

      setLobbiesData(transformedData);

      return;
    },
  });

  useEffect(() => {
    (async (): Promise<void> => {
      await fetchLobbiesBySlug();
    })();
  }, []);

  useEffect(() => {
    if (subcategory) {
      const filteredLobbyData = filterLobbyData(lobbiesData, subcategory);

      setFilteredLobbiesData(filteredLobbyData);
    } else {
      setFilteredLobbiesData(lobbiesData);
    }
  }, [subcategory, lobbiesData]);

  return (
    <div className="casino-content">
      <NavigationTemplate categories={lobbiesData?.showCategories ? categories : []}>
        {fetchedLobbiesLoading ? (
          <div className="spinner-wrapper">
            <Spinner theme="light" />
          </div>
        ) : (
          <>
            {filteredLobbiesData?.bannerCollection && (
              <HeroBannerCarousel
                slides={filteredLobbiesData?.bannerCollection.banners}
                autoplayInterval={5000}
                isDisabledSlideContent={areGamesDisabled}
                staticBanner={filteredLobbiesData?.bannerCollection.staticBanner}
              />
            )}
            {filteredLobbiesData?.lobbyItems?.length ? (
              filteredLobbiesData.lobbyItems.map((item) => (
                <div key={item.title}>
                  <GamesCarouselList
                    rowsNumber={1}
                    title={item?.title}
                    mobileSlideCount={1}
                    tabletSlideCount={4}
                    desktopSlideCount={6}
                    catalogue={item.games}
                    fallbackImageUrl="url"
                    autoplayInterval={5000}
                    bigDesktopSlideCount={6}
                    disabled={areGamesDisabled}
                    loadMoreButtonTitle="Load More"
                    showTitle={Boolean(item?.title)}
                    isLoading={fetchedLobbiesLoading}
                    fallbackImageAltText="fallback alt"
                    gameCardContainerType={item.carouselType}
                  />
                  {subcategory && <InnerHTML text={item.description as string} />}
                </div>
              ))
            ) : (
              <Paragraph>There're no available games</Paragraph>
            )}
            {!subcategory && <InnerHTML text={lobbiesData?.generalDescription as string} />}
          </>
        )}
      </NavigationTemplate>
    </div>
  );
};
